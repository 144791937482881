<template>
  <div>
    <div style="padding: 10px">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          style="height: 100%"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, key) in list" :key="key">
            <div style="padding: 10px">
              <van-row @click="modify(item)">
                <van-col class="itemCol" style="font-weight: bolder" span="24"
                  >{{ item.plate_number }}
                </van-col>
                <van-col
                  class="itemCol"
                  style="font-size: 10px; color: darkgrey"
                  span="24"
                >
                  录入时间：{{ item.registration_time }}</van-col
                >
                <van-col v-if="tongerbao" class="textCol" span="12">
                  总人数：{{
                    numberFormat(item.effective_number_artificial) +
                    numberFormat(item.invalid_number_artificial)
                  }}
                </van-col>
                <van-col v-else class="textCol" span="12">
                  总人数：{{
                    numberFormat(item.effective_number_artificial) +
                    numberFormat(item.invalid_number_artificial) +
                    numberFormat(item.code_number) +
                    item.sign_up_ids.length
                  }}
                </van-col>
                <van-col class="textCol" span="12"
                  >扫描人数：{{
                    item.sign_up_ids.length + numberFormat(item.code_number)
                  }}</van-col
                >
                <van-col class="textCol" span="12"
                  >无效人数：{{ item.invalid_number_artificial }}</van-col
                >
                <van-col class="textCol" span="12"
                  >有效人数：{{ item.effective_number_artificial }}</van-col
                >
              </van-row>
              <van-divider />
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-tabbar>
      <van-button
        round
        block
        type="info"
        style="margin: 10px"
        size="small"
        @click="upload"
      >
        全部上传
      </van-button>
      <van-button
        round
        block
        type="info"
        style="margin: 10px"
        size="small"
        @click="clear"
      >
        清空
      </van-button>
    </van-tabbar>
  </div>
</template>

<style>
.itemCol {
  text-align: left;
  padding-bottom: 10px;
}

.textCol {
  text-align: left;
  padding-bottom: 10px;
  font-size: 14px;
  color: #424949;
}
</style>

<script>
import {
  List,
  Tabbar,
  Button,
  Col,
  Row,
  Divider,
  PullRefresh,
  Dialog,
  Toast,
} from "vant";

export default {
  components: {
    vanList: List,
    vanTabbar: Tabbar,
    vanButton: Button,
    vanCol: Col,
    vanRow: Row,
    vanDivider: Divider,
    vanPullRefresh: PullRefresh,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,

      tongerbao: false,
    };
  },
  mounted() {
    if (this.$store.getters.DATAUPLOAD) {
      this.list = this.$store.getters.DATAUPLOAD.bus_list;
    }
    console.log(this.list);
    // this.check();
    if (this.$store.getters.USER.domain === "DOMAIN-9") {
      this.tongerbao = true;
    } else {
      this.tongerbao = false;
    }
  },
  methods: {
    numberFormat(string) {
      if (string) {
        return parseInt(string);
      } else {
        return 0;
      }
    },
    onLoad() {
      let sample = [];
      for (var i = 0; i < this.list.length; i++) {
        sample.push({
          id: i,
          plate_number: this.list[i].plate_number,
          registration_time: this.list[i].registration_time,
        });
      }
      this.http.post(
        this.ports.business.dataCheck(this.$store.getters.USER.domain),
        {
          bus_list: sample,
        },
        (res) => {
          console.log(res);

          for (var i = 0; i < res.length; i++) {
            let car = {
              plate_number: sample[res[i]].plate_number,
              registration_time: sample[res[i]].registration_time,
            };
            this.$store.dispatch("DELETE_CAR", car);
          }

          if (this.$store.getters.DATAUPLOAD) {
            this.list = this.$store.getters.DATAUPLOAD.bus_list;
          }
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        },
        (err) => {
          console.log(err);
          if (this.$store.getters.DATAUPLOAD) {
            this.list = this.$store.getters.DATAUPLOAD.bus_list;
          }
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        }
      );
    },
    upload() {
      Dialog.confirm({
        title: "提示",
        message: "确认全部上传吗？",
      })
        .then(() => {
          // on confirm
          this.http.post(
            this.ports.business.dataUpload(this.$store.getters.USER.domain),
            this.$store.getters.DATAUPLOAD,
            (res) => {
              console.log(res);
              Toast.success("已上传");
              this.$router.replace({
                path: "/",
              });
            },
            (err) => {
              Toast.fail("上传失败");
              console.log(err);
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    clear() {
      Dialog.confirm({
        title: "提示",
        message: "确认全部清空吗？",
      })
        .then(() => {
          this.$store.dispatch("CLEAR_DATA");
        })
        .catch(() => {
          // on cancel
        });
    },
    modify(car) {
      Dialog.confirm({
        title: "车牌号：" + car.plate_number,
        message: "确认修改这辆车吗？",
      })
        .then(() => {
          // on confirm
          this.$router.push({
            name: "scan",
            params: {
              car_number: car.plate_number,
              registration_time: car.registration_time,
              effective_number_artificial: car.effective_number_artificial,
              invalid_number_artificial: car.invalid_number_artificial,
              code_number: car.code_number,
              sign_up_ids: car.sign_up_ids,
              users: car.users,
            },
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>